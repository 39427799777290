import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import IntelMatsubi from "components/cpu/intel_matsubi";
import AMDMatsubi from "components/cpu/amd_matsubi";
import { Link, graphql } from "gatsby";
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu-select"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/cpu.jpg"}) { ...eyecatchImg },
    cpumodule_s: file(relativePath: { eq: "cpu/cpu-module_s.png"}) { ...smallImg },
    cpumaker: file(relativePath: { eq: "cpu/cpu-maker.png"}) { ...normalImg },
    generation: file(relativePath: { eq: "cpu/generation.png"}) { ...normalImg },
    alphabet: file(relativePath: { eq: "cpu/alphabet.png"}) { ...normalImg },
    kataban: file(relativePath: { eq: "cpu/kataban.png"}) { ...normalImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    multicore: file(relativePath: { eq: "cpu/multicore.png"}) { ...normalImg },
    turboboost: file(relativePath: { eq: "cpu/turboboost.png"}) { ...normalImg },
    cryzenintel: file(relativePath: { eq: "cover/cpu/ryzen-intel.png"}) { ...eyecatchImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`CPU（プロセッサー）の選び方`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="CPUの選び方" mdxType="Image" />
    <p>{`パソコンの性能を決定する上で最も重要な役割を果たすCPU、インテルのCore iシリーズが有名であり、店頭に行くと`}<em parentName="p">{`「Core i5なので安心です。」`}</em>{`などと説明を受けることもある。`}</p>
    <p>{`しかし同じCore i5でも100種類以上存在し、デスクトップ向けやノート向け、発売次期などによって性能は大きく異なってしまう。`}</p>
    <p>{`本ページではCPUの基本から、型番やスペック表の読み方、ベンチマークテストなど、性能を測る上で重要な項目について解説し、用途や予算にあった最適なCPUを自分で選択できるようにすることが目的である。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "CPUとは何か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#CPU%E3%81%A8%E3%81%AF%E4%BD%95%E3%81%8B%EF%BC%9F",
        "aria-label": "CPUとは何か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUとは何か？`}</h2>
    <Image {...props} name="cpumodule_s" alt="プロセッサー" mdxType="Image" />
    <p>{`CPU(Central Processing Unit:中央演算処理装置)とはパソコンの頭脳に当たり、`}<strong parentName="p">{`パソコンの演算処理を主に担当`}</strong>{`する。アプリケーションから来た命令、たとえばファイルを移動させる、Excelで数値計算した値をセルに反映させるなど`}<strong parentName="p">{`あらゆる操作をCPUがこなす`}</strong>{`のである。`}</p>
    <p>{`CPUの性能が悪いと指令に素早く答えることができず、パソコンの動作が鈍くなる、使用者にはストレスとなる。`}</p>
    <p>{`たとえば、Webサイトを見ている時にマウスのスクロールが滑らかでない、動画がカクカクする、動画形式の変換が遅い、急にクリックが反応しづらくなった、なかなかアプリケーションが立ち上がらない、ブラウザのタブの開きが遅い、などはCPUが主因となるケースが多い。`}</p>
    <h2 {...{
      "id": "パソコン用CPUはIntelとAMDの２社から選択する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E7%94%A8CPU%E3%81%AFIntel%E3%81%A8AMD%E3%81%AE%EF%BC%92%E7%A4%BE%E3%81%8B%E3%82%89%E9%81%B8%E6%8A%9E%E3%81%99%E3%82%8B",
        "aria-label": "パソコン用CPUはIntelとAMDの２社から選択する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコン用CPUはIntelとAMDの２社から選択する`}</h2>
    <Image {...props} name="cpumaker" alt="IntelとAMD" mdxType="Image" />
    <p>{`パソコンの完成品はレノボやデル、ヒューレッドパッカード、NEC、富士通、マウスコンピュータなど複数のメーカーが開発しているが、その内部で使われている`}<strong parentName="p">{`CPUの提供メーカーは`}<em parentName="strong">{`Intel`}</em>{`と`}<em parentName="strong">{`AMD`}</em>{`の２社のみ`}</strong>{`である。`}</p>
    <p>{`Intelは`}<em parentName="p">{`「インテル入ってる？」`}</em>{`で知られるアメリカの半導体メーカーであり、最近はAMDにやや押され気味ではあるものの、長らくCPU市場のシェアを独走し続けてきた。`}</p>
    <p>{`一方でAMDもアメリカの半導体メーカーでCPUの他、グラフィック専用のGPUも製造している。`}</p>
    <p>{`パソコン用CPU向けのミドルレンジ以上のブランドとして`}<strong parentName="p">{`Intelは`}<em parentName="strong">{`Core i`}</em>{`シリーズ`}</strong>{`、`}<strong parentName="p">{`AMDは`}<em parentName="strong">{`Ryzen`}</em>{`シリーズ`}</strong>{`という製品を発売している。`}</p>
    <p>{`低価格向けとしてIntelはCeleron、AMDはAthlonという製品も発売しているものの、低スペックで動作が遅いために、基本的にはCore iシリーズかRyzenを選んでいくことになる。`}</p>
    <h2 {...{
      "id": "CPUの性能に重要な要素１：シリーズと世代",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#CPU%E3%81%AE%E6%80%A7%E8%83%BD%E3%81%AB%E9%87%8D%E8%A6%81%E3%81%AA%E8%A6%81%E7%B4%A0%EF%BC%91%EF%BC%9A%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA%E3%81%A8%E4%B8%96%E4%BB%A3",
        "aria-label": "CPUの性能に重要な要素１：シリーズと世代 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUの性能に重要な要素１：シリーズと世代`}</h2>
    <Image {...props} name="generation" alt="CPUのシリーズと世代" mdxType="Image" />
    <p>{`ひとことにCore i CPU、Ryzen CPUといってもその種類は数百種類を超え、性能も千差万別である。これらを区別するために必要なより細分化されたシリーズ及び、世代についてまずは説明する。`}</p>
    <h3 {...{
      "id": "シリーズは数字が上のものほど高性能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA%E3%81%AF%E6%95%B0%E5%AD%97%E3%81%8C%E4%B8%8A%E3%81%AE%E3%82%82%E3%81%AE%E3%81%BB%E3%81%A9%E9%AB%98%E6%80%A7%E8%83%BD",
        "aria-label": "シリーズは数字が上のものほど高性能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`シリーズは数字が上のものほど高性能`}</h3>
    <p>{`Core iシリーズは`}<strong parentName="p"><em parentName="strong">{`Core i3、Core i5、Core i7、Core i9`}</em></strong>{`と更に細かくシリーズ分けされており、`}<strong parentName="p">{`数値が大きいほどハイエンドなCPU`}</strong>{`である。`}</p>
    <p>{`Ryzenシリーズについても同様のことが言え、`}<strong parentName="p"><em parentName="strong">{`Ryzen3`}</em>{`、`}<em parentName="strong">{`Ryzen5`}</em>{`、`}<em parentName="strong">{`Ryzen7`}</em>{`、`}<em parentName="strong">{`Ryzen9`}</em></strong>{`とシリーズ分けがされており、数値が大きいほどハイエンドなCPUである。`}</p>
    <p>{`それぞれラインナップを表にすると次のようになる。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <caption>CPUの製品ラインナップ</caption>
  <thead>
    <tr>
      <th>カテゴリー</th>
      <th>メーカー</th>
      <th>CPU名</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td rowSpan="4">ハイエンド</td>
      <td rowSpan="2">Intel</td>
      <td>Core i9</td>
    </tr>
    <tr>
      <td>Core i7</td>
    </tr>
    <tr>
      <td rowSpan="2">AMD</td>
      <td>Ryzen 9</td>
    </tr>
    <tr>
      <td>Ryzen 7</td>
    </tr>
    <tr>
      <td rowSpan="4">ミドルエンド</td>
      <td rowSpan="2">Intel</td>
      <td>Core i5</td>
    </tr>
    <tr>
      <td>Core i3</td>
    </tr>
    <tr>
      <td rowSpan="2">AMD</td>
      <td>Ryzen 5</td>
    </tr>
    <tr>
      <td>Ryzen 3</td>
    </tr>
    <tr>
      <td rowSpan="5">ローエンド</td>
      <td rowSpan="2">Intel</td>
      <td>Pentium</td>
    </tr>
    <tr>
      <td>Celeron</td>
    </tr>
    <tr>
      <td>AMD</td>
      <td>Athlon</td>
    </tr>
  </tbody>
    </BasicTable>
    <h3 {...{
      "id": "世代が変わるたびに性能が20程度上昇",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%B8%96%E4%BB%A3%E3%81%8C%E5%A4%89%E3%82%8F%E3%82%8B%E3%81%9F%E3%81%B3%E3%81%AB%E6%80%A7%E8%83%BD%E3%81%8C20%E7%A8%8B%E5%BA%A6%E4%B8%8A%E6%98%87",
        "aria-label": "世代が変わるたびに性能が20程度上昇 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`世代が変わるたびに性能が20%程度上昇`}</h3>
    <p>{`また、各シリーズはほぼ`}<strong parentName="p">{`毎年アーキテクチャが刷新されて同名のシリーズとして発売される`}</strong>{`。`}</p>
    <p>{`この毎年刷新される`}<strong parentName="p">{`アーキテクチャが、最初から数えて何代目かを表した言葉が`}<em parentName="strong">{`世代`}</em>{`であり、世代が新しいものほど性能がアップする`}</strong>{`。年にもよるがおおよそ20%程度性能が上がることが多い。`}</p>
    <p>{`世代の見分け方は型番の項目で詳しく説明するが、`}<strong parentName="p">{`シリーズ名に続く最初の１，２桁の数値が世代を表す`}</strong>{`。Core i5-12700Kだと12世代目、Core i7-1360Pだと13世代といった塩梅である。`}</p>
    <p>{`Ryzenに対しても同じことが言え、Ryzen 9-7950Xだと7世代目のRyzenということができる。厳密にはRyzenの場合アーキテクチャの世代と番号が異なるため７世代と公式には言われていないが、ともあれ`}<strong parentName="p"><em parentName="strong">{`数字が大きいほど新しい`}</em></strong>{`。`}</p>
    <p>{`また`}<strong parentName="p">{`それぞれの世代について下記のように`}<em parentName="strong">{`開発コードネーム`}</em>{`がつけられており、コードネームでCPUが語られることもある`}</strong>{`ため、覚えておいても損はない。`}</p>
    <BasicTable mdxType="BasicTable">
  <tbody>
    <tr>
      <th>世代</th>
      <th>開発コードネーム</th>
    </tr>
    <tr>
      <td>第14世代</td>
      <td>Raptor Lake Refresh</td>
    </tr>
    <tr>
      <td>第13世代</td>
      <td>Raptor Lake</td>
    </tr>
    <tr>
      <td>第12世代</td>
      <td>Alder Lake</td>
    </tr>
    <tr>
      <td>第11世代(ノートPC)</td>
      <td>Tiger Lake</td>
    </tr>
    <tr>
      <td>第11世代(デスクトップPC)</td>
      <td>Rocket Lake-S</td>
    </tr>
    <tr>
      <td>第10世代</td>
      <td>Ice Lake</td>
    </tr>
    <tr>
      <td>第9世代</td>
      <td>Cofee Lake Refresh-S</td>
    </tr>
    <tr>
      <td>第8世代</td>
      <td>Cofee Lake</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`ちなみに、コードネームは開発段階でつける製品（またはプロジェクト)の名前であるが、発売されていない製品に関する会話をプロジェクトメンバーが円滑に進めるられるようにつけるものである。`}</p>
    <p>{`このため製品の宣伝でコードネームを使うのは、単にマニアックなギーク向けというわけである。`}</p>
    <h2 {...{
      "id": "CPUの性能に重要な要素２：末尾のアルファベット文字",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#CPU%E3%81%AE%E6%80%A7%E8%83%BD%E3%81%AB%E9%87%8D%E8%A6%81%E3%81%AA%E8%A6%81%E7%B4%A0%EF%BC%92%EF%BC%9A%E6%9C%AB%E5%B0%BE%E3%81%AE%E3%82%A2%E3%83%AB%E3%83%95%E3%82%A1%E3%83%99%E3%83%83%E3%83%88%E6%96%87%E5%AD%97",
        "aria-label": "CPUの性能に重要な要素２：末尾のアルファベット文字 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUの性能に重要な要素２：末尾のアルファベット文字`}</h2>
    <Image {...props} name="alphabet" alt="CPUの末尾の文字を理解する" mdxType="Image" />
    <p>{`シリーズと世代が理解できたならばCPUの選び方を半分程度理解したと言える。しかしCore i7の第〇〇世代という情報だけでは、まだそのCPUがどの程度の性能を有しているのか十分に判断できない。`}</p>
    <p>{`そこで、もう一つの重要な要素が用途である。`}<strong parentName="p">{`型番としてはお尻のアルファベットが用途を表しており`}</strong>{`、Core i9-13900KならばKが用途である。必ずしも用途だけではないが、CPUの特徴を十分に表している。`}</p>
    <p>{`ちなみにKはオーバークロックの意味で、消費電力を上げて性能を引き上げるオプション機能が付属しているという意味合いである。`}</p>
    <p>{`Intelの代表的なものは上の図のようになる。消費電力を横軸に、性能を縦軸にとると大体の文字は整理ができる。`}</p>
    <p><strong parentName="p">{`特にノートPCは消費電力がバッテリーの持ちに大きく関係するため、`}<em parentName="strong">{`性能を取るか、ロングバッテリーを取るか`}</em>{`を選択する必要が出てくる`}</strong>{`。`}</p>
    <h3 {...{
      "id": "Intel-CPUの末尾文字の意味",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Intel-CPU%E3%81%AE%E6%9C%AB%E5%B0%BE%E6%96%87%E5%AD%97%E3%81%AE%E6%84%8F%E5%91%B3",
        "aria-label": "Intel CPUの末尾文字の意味 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Intel CPUの末尾文字の意味`}</h3>
    <p>{`IntelとAMDのCPUの末尾文字をまとめておく。まずはIntelから。`}</p>
    <IntelMatsubi mdxType="IntelMatsubi" />
    <p>{`いくつか注目点を上げる。まず、Fはグラフィックボード搭載が必須となっており、搭載しないと画面に何も出力することができないモデルとなる。単品で購入する場合は注意が必要となる。`}</p>
    <p>{`Gは11世代以上のものならゲームもある程度動かすことができ、`}<strong parentName="p">{`ノートパソコンで映像編集やゲームを重視するならば良い選択肢`}</strong>{`となる。`}</p>
    <p>{`Yは手軽さと低消費電力でロングバッテリーを実現できる事が売りであるが、`}<strong parentName="p">{`性能が低く、テレワークなどのオフィス作業にも耐えられない可能性が高い`}</strong>{`。仕事用途では避けておいた方が無難であろう。`}</p>
    <h3 {...{
      "id": "AMD-CPUの末尾文字の意味",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#AMD-CPU%E3%81%AE%E6%9C%AB%E5%B0%BE%E6%96%87%E5%AD%97%E3%81%AE%E6%84%8F%E5%91%B3",
        "aria-label": "AMD CPUの末尾文字の意味 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`AMD CPUの末尾文字の意味`}</h3>
    <p>{`次にAMDのケースは次のようになる。`}</p>
    <AMDMatsubi mdxType="AMDMatsubi" />
    <p>{`AMDのデスクトップCPUの通常版やX版はIntelのFと同様でグラフィックボードの搭載が必須である。単品での購入は注意しよう。`}</p>
    <p>{`ノートPC用はあまり種類が多くないため判別が付きやすいだろう。`}</p>
    <h2 {...{
      "id": "CPUの型番の読み方を解説",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#CPU%E3%81%AE%E5%9E%8B%E7%95%AA%E3%81%AE%E8%AA%AD%E3%81%BF%E6%96%B9%E3%82%92%E8%A7%A3%E8%AA%AC",
        "aria-label": "CPUの型番の読み方を解説 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUの型番の読み方を解説`}</h2>
    <Image {...props} name="kataban" alt="CPUの型番を理解する" mdxType="Image" />
    <p>{`ここまでの解説でほとんど読めるようになっていると思われるが、型番は図のように`}<strong parentName="p">{`左から`}<em parentName="strong">{`シリーズ名`}</em>{`、`}<em parentName="strong">{`世代`}</em>{`、`}<em parentName="strong">{`マイナーバージョン`}</em>{`、`}<em parentName="strong">{`用途`}</em></strong>{`の順で書かれている。`}</p>
    <p>{`マイナーバージョンは、さらに細かい製品分けで使われるバージョンで、`}<strong parentName="p">{`同シリーズ、同世代のCPUの場合はマイナーバージョンで性能を区別できる`}</strong>{`。そこまで性能差は大きくないが、`}<strong parentName="p">{`バージョンが高いものほど高性能`}</strong>{`であると考えてよい。`}</p>
    <p>{`CPU選びの基本は、まずシリーズ名と世代からどのレンジのCPUかを判断し、用途にあった種類のCPUを選択し、価格と照らし合わせて判断することである。`}</p>
    <h2 {...{
      "id": "ベンチマークから性能を判断する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%99%E3%83%B3%E3%83%81%E3%83%9E%E3%83%BC%E3%82%AF%E3%81%8B%E3%82%89%E6%80%A7%E8%83%BD%E3%82%92%E5%88%A4%E6%96%AD%E3%81%99%E3%82%8B",
        "aria-label": "ベンチマークから性能を判断する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ベンチマークから性能を判断する`}</h2>
    <p>{`型番の読み方でおおよその選び方は理解いただけたかと思うが、いくつか疑問も残る。`}</p>
    <ul>
  <li>前世代のCore i7と新世代のCore i5ではどちらの性能が上か？</li>
  <li>Core iシリーズとRyzenのとあるCPUでどちらの性能が上か？</li>
  <li>デスクトップ向けとノート向けCPUでどれぐらい性能が異なるのか？</li>
  <li>用途による向き、不向きがイマイチ分からない</li>
    </ul>
    <p>{`このような型番だけでは判別がつかない問題に答えるために、`}<strong parentName="p">{`CPUの性能を多角的に判断するベンチマークテストが外部の機関・企業により提供され、その結果が公開されている`}</strong>{`。`}</p>
    <p>{`有名なベンチマークを３つ紹介しておく。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>テスト名</th>
      <th>説明</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>PassMark</td>
      <td>CPUの総合的な性能を測るテスト、オーストラリアの有限会社が運営しておりベンチマークとしては信頼性が高い。</td>
    </tr>
    <tr>
      <td>3DMark</td>
      <td>3Dの描画能力を図るためのベンチマーク、この値が高いほど3DやVRゲームで滑らかな描画を実現できる。</td>
    </tr>
    <tr>
      <td>Cinebench</td>
      <td>画像のレンダリング性能を測るソフト、この値が高いほど3Dのモデリングなどの映像編集作業に向いている。</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`基本的に`}<strong parentName="p">{`高度な3Dゲームやエフェクトの伴うような映像編集を行う人以外はPassMarkの結果を参考にすると良い`}</strong>{`。ベンチマーク結果をスコアの目安とともに表としてまとめてあるので下記を参考にして欲しい。`}</p>
    <Card {...props} title="CPU性能比較表" description="400を超えるCPUから高機能フィルタにより世代間/ベンチマーク/TDP/コスパ等の絞りこみが行えます。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <h2 {...{
      "id": "スペック表から性能を判断する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E8%A1%A8%E3%81%8B%E3%82%89%E6%80%A7%E8%83%BD%E3%82%92%E5%88%A4%E6%96%AD%E3%81%99%E3%82%8B",
        "aria-label": "スペック表から性能を判断する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`スペック表から性能を判断する`}</h2>
    <p>{`パソコンのCPU仕様の欄には(`}<em parentName="p">{`4コア/3.00GHz/TB時最大3.50GHz/6MB スマートキャッシュ`}</em>{`)なども書かれている。`}</p>
    <p>{`これらの数値が高さが直接性能の高さに繋がるわけではないが、CPUの特性を理解するうえで役に立つので解説しておく。`}</p>
    <h3 {...{
      "id": "コア数",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B3%E3%82%A2%E6%95%B0",
        "aria-label": "コア数 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`コア数`}</h3>
    <p><strong parentName="p">{`CPUは演算を行う中核である`}<em parentName="strong">{`コア`}</em>{`というパーツを持ち、この数がコア数`}</strong>{`である。`}</p>
    <p>{`複数のアプリケーションを同時に実行しようとした時、一つのコアに集中して処理を任せるのではなく、複数のコアに任せることで負荷分散をさせ、アプリの動作を軽くすることができる。`}</p>
    <p>{`また、単一のアプリケーションを実行する場合であっても、その`}<strong parentName="p"><em parentName="strong">{`アプリケーションの処理がマルチコアに対応している場合`}</em>{`には、処理を軽快にこなすことができる`}</strong>{`。イメージを書くと次のような感じになる。`}</p>
    <Image {...props} name="multicore" alt="シングルコアとマルチコア" mdxType="Image" />
    <p>{`マルチコアへの対応は完全にアプリによりけりであり、同じアプリ内であってもマルチコアを必要とする処理としない処理に分けられる。`}</p>
    <p>{`画像の緻密な光の当たり具合を計算する`}<em parentName="p">{`レンダリング処理`}</em>{`、Youtubeに動画をアップロードするためなど動画形式を変換する`}<em parentName="p">{`エンコード処理`}</em>{`、あるいは`}<em parentName="p">{`AI処理`}</em>{`などはフルにマルチコアの能力が使われる事が多い。`}</p>
    <p>{`一方でブラウザやオフィスの単純な操作はシングルコアのタスクであり、コア数が多くても恩恵は受けない。`}<strong parentName="p">{`ゲームはある程度マルチコアを有効利用しているが、`}<em parentName="strong">{`映像編集やAI処理ほどコア数を必要としない`}</em></strong>{`傾向がある。`}</p>
    <p><strong parentName="p">{`アプリごとに使用されるコア数には上限がある`}</strong>{`という事実も抑えておきたい。`}</p>
    <p>{`他アプリの動作状況も加味した上で、`}<strong parentName="p">{`性能を上げるための限界コア数は大雑把だが、オフィスやブラウザは4コア、ゲームは10コア、映像編集やAI処理は多いほど良い`}</strong>{`ぐらいの感覚である。`}</p>
    <h3 {...{
      "id": "スレッド数",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B9%E3%83%AC%E3%83%83%E3%83%89%E6%95%B0",
        "aria-label": "スレッド数 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`スレッド数`}</h3>
    <p>{`次に`}<strong parentName="p"><em parentName="strong">{`スレッド数`}</em>{`は、`}<em parentName="strong">{`ソフトウェア側から見えるコア数`}</em>{`のこと`}</strong>{`を言う。実際の物理的なコア数とは異なるため、`}<em parentName="p">{`仮想的なコア数`}</em>{`とも呼ばれる。`}</p>
    <p>{`一つのコアにはいくつもの演算ユニットが存在するが、全ての演算ユニットが常に使用されるわけではない。`}</p>
    <p>{`そこでソフトウェアがCPUに命令する入口(=`}<em parentName="p">{`スレッド`}</em>{`)を増やし、余剰の演算ユニットを効率的に使えるようにしようという考えが生まれた。`}</p>
    <p>{`１コア２スレッドの場合、コア自体は１つであるが、そのコアに対して２つの命令が同時に行え、中の演算ユニットやメモリなどのモジュールを共有して効率的に使用するのである。`}</p>
    <p>{`物理的なコア数はあくまで１つであるため、性能的には`}<strong parentName="p"><em parentName="strong">{`２コア２スレッドは、１コア２スレッドのCPUに劣る`}</em>{`ことは自明`}</strong>{`である。`}</p>
    <p>{`このスレッド技術であるが、Intelは`}<em parentName="p">{`Hyper Threading Technology(HTT)`}</em>{`、AMDは`}<em parentName="p">{`Simultineous Multi Threading(SMT)`}</em>{`と名付けている。どちらも実装は異なれど仕組みとしては同等のものである。`}</p>
    <h3 {...{
      "id": "クロック周波数動作周波数-GHz",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AF%E3%83%AD%E3%83%83%E3%82%AF%E5%91%A8%E6%B3%A2%E6%95%B0%E5%8B%95%E4%BD%9C%E5%91%A8%E6%B3%A2%E6%95%B0-GHz",
        "aria-label": "クロック周波数動作周波数 GHz permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`クロック周波数(動作周波数) (GHz)`}</h3>
    <p>{`CPUはより物理的な仕組みとして、電圧の波を発生させ、その波の変動により同期を取り計算を実行している。`}<strong parentName="p">{`コアごとに１秒間に発生させることができる電波の波の数をクロック周波数と呼び、`}<em parentName="strong">{`命令を処理することが可能なタイミングの数`}</em></strong>{`と考えれば良い。`}</p>
    <p>{`クロック周波数の単位としてはGHz(ギガヘルツ)が使われるが、5GHzならば１秒間に50億回電波の波を発生させていることになる。`}</p>
    <p>{`基本的にはクロック周波数が高いCPUほどシングルコアでの性能が高いと言えるが、`}<strong parentName="p">{`１回の命令で実行できるタスク量はCPUのアーキテクチャによるため、`}<em parentName="strong">{`単純にクロック周波数だけでシングルコアの実力を測ることは困難`}</em></strong>{`である。`}</p>
    <p>{`よって同世代のCPUの比較程度にとどめておくのが現実的な見方であろう。`}</p>
    <h3 {...{
      "id": "ターボブーストテクノロジー",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%BF%E3%83%BC%E3%83%9C%E3%83%96%E3%83%BC%E3%82%B9%E3%83%88%E3%83%86%E3%82%AF%E3%83%8E%E3%83%AD%E3%82%B8%E3%83%BC",
        "aria-label": "ターボブーストテクノロジー permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ターボブーストテクノロジー`}</h3>
    <Image {...props} name="turboboost" alt="ターボブーストテクノロジー" mdxType="Image" />
    <p>{`CPUにもよるが、各コアはCPU内の温度や、他のコアの使用状況に応じてクロック周波数を一時的に引き上げる機能を持つ。`}</p>
    <p>{`Intelはこれを`}<em parentName="p">{`Turbo Boostテクノロジー(TB)`}</em>{`と、AMDは`}<em parentName="p">{`Turbo Coreテクノロジー`}</em>{`とそれぞれ命名しており、スペック表では`}<strong parentName="p">{`TB時最大3.50GHzや2.2GHz(Max 4GHz)など、通常のクロック周波数とは区別して記載される`}</strong>{`。`}</p>
    <p>{`この機能により、`}<strong parentName="p">{`特定のシングルコアに重い負荷が加わった時の処理を加速させる`}</strong>{`ことができる。ゲームの他、通常のオフィスの起動、Excelで計算を回す時など様々なシーンで用いられている。`}</p>
    <h3 {...{
      "id": "キャッシュ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5",
        "aria-label": "キャッシュ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`キャッシュ`}</h3>
    <p>{`スマートキャッシュ6MB等スペック表に記載されているがこれは何か？キャッシュとは一時的に計算結果などを保存しておく領域の事で、この場合CPUの中にキャッシュ機能を積んでいる。`}</p>
    <p>{`ハードディスクやSSD、メモリーも同様にデータを保存する領域であるが、CPUとの物理的な距離が離れているため演算処理の途中結果を保存しておくには効率が悪い。`}</p>
    <p>{`しかし`}<strong parentName="p">{`CPU中のキャッシュはCPUのコアと物理的に近い場所にあり、かつ少量ながら高品質であるため非常に高速`}</strong>{`である。さらにスマートキャッシュという技術により、より効率的にこの少量のキャッシュを使用することができる。`}</p>
    <p>{`基本的には`}<strong parentName="p">{`キャッシュの値が大きいほどCPUの性能が高まる`}</strong>{`と考えておけば良いだろう。`}</p>
    <h3 {...{
      "id": "製造プロセスルール",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%A3%BD%E9%80%A0%E3%83%97%E3%83%AD%E3%82%BB%E3%82%B9%E3%83%AB%E3%83%BC%E3%83%AB",
        "aria-label": "製造プロセスルール permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`製造プロセスルール`}</h3>
    <p>{`直訳すると製造工程のことだが、CPUなど半導体製品においては集積回路の線の幅のことを言う。単位としてはnm(ナノメートル)を使う。それほど線は細いのである。`}</p>
    <p>{`良く新製品で10nm(ナノメートル)の微細化を実現などと書かれてあり、だからどうなんだと思うのが普通であるが、`}<strong parentName="p">{`微細化すると低消費電力で高性能を発揮しやすくなる`}</strong>{`。`}</p>
    <p>{`次世代CPU発売などアーキテクチャ刷新のタイミングで更新されることが多い。`}</p>
    <p>{`裏の話であるが、実際のところどの幅のことを言っているのか各社により定義が違うため`}<strong parentName="p">{`IntelとAMDでの比較は難しく、単にマーケティング用語になっているだけの節もある`}</strong>{`。あまり気にする必要は無い。`}</p>
    <h2 {...{
      "id": "Core-iシリーズ-vs-Ryzen-どちらがおすすめか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA-vs-Ryzen-%E3%81%A9%E3%81%A1%E3%82%89%E3%81%8C%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%8B%EF%BC%9F",
        "aria-label": "Core iシリーズ vs Ryzen どちらがおすすめか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core iシリーズ vs Ryzen どちらがおすすめか？`}</h2>
    <p>{`ソニーか任天堂のどちらが良いかというゲハ戦争(ゲームハード戦争)のようにIntelとAMDのどちらが良いかという比較議論が界隈ではよくされる。`}</p>
    <p>{`論争は様々であり、またIntelもAMDも様々な種類のCPUを様々な価格帯で販売しているものだから、一概にどちらが良いかを論じることは無理がある。`}</p>
    <p>{`ただし、全体的な傾向としてはRyzenは価格比でマルチコア性能が高く、`}<strong parentName="p">{`3Dモデリングやプログラミング`}</strong>{`などのクリエイティブな用途で活躍が期待でき、`}<strong parentName="p">{`Intelは有名ソフトウェアに関してソフトウェアの最適化を行っている`}</strong>{`ため、オフィスソフト、Adobeなどで快適に、そして安定的に動作が期待できる。`}</p>
    <p>{`細かい内容は下記にまとめたので参考にして欲しい。`}</p>
    <Card {...props} title="RyzenとIntelの違いを比較する" description="マルチコア性能のRyzenとソフトウェア最適化のIntel、ゲーム、動画編集、オフィス等の用途別おすすめは？" image="cryzenintel" path="/cpu/ryzen-intel/" mdxType="Card" />
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`CPUの型番やスペックの読み方を説明した。これである程度CPUについて理解できるようになったはずである。`}</p>
    <p>{`あとは`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`等を参考に最終的なCPUを決定していってほしい。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      